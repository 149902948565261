import React from 'react'
import * as Unicons from '@iconscout/react-unicons';

export default function Contact() {
  return (
    <>
        <section id="contact">
            <div className="container">
                <div className="contact-card">
                    <div className="infos">
                        <h6 className="section-subtitle">Get Here</h6>
                        <h6 className="section-title mb-4">Contact Us</h6>

                        <div className="item">
                            <Unicons.UilLocationPoint/>
                            <div className="">
                                <h5> Location</h5>
                                <p> Jl. Pemuda no.37 B4 Taman Sari Bukit Damai</p>
                                <p> Padurenan, Gn Sindur - Bogor Jawa Barat</p>
                                <p> Indonesia</p>
                            </div>                          
                        </div>
                        <div className="item">
                            <Unicons.UilPhone/>
                            <div>
                                <h5>Phone Number</h5>
                                <p>+628164644904</p>
                            </div>                          
                        </div>
                        <div className="item">
                            <Unicons.UilFastMail/>
                            <div className="mb-0">
                                <h5>Email Address</h5>
                                <p>official@ansgraph.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="form">
                        <h6 className="section-subtitle">Available 24/7</h6>
                        <h6 className="section-title mb-4">Get In Touch</h6>
                        <form>
                            <div className="form-group">
                                <input type="email" className="form-control form-control-lg" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" required/>
                            </div>
                            <div className="form-group">
                                {/* <input type="password" className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" required/> */}
                                <input type="text" className='form-control form-control-lg' id='subject' placeholder='Subject' required/>
                            </div>
                            <div className="form-group">
                                <textarea name="contact-message" id="" cols="30" rows="7" className="form-control form-control-lg" placeholder="Message"/>
                            </div>
                            <button type="submit" className="btn btn-primary btn-block btn-lg mt-3">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    </>
  )
}
