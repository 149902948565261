import React from 'react'
import { useEffect, useState } from 'react';
import '../../../assets/css/creative-studio.css'

export default function NavbarClicked({links, classLi, classA}) {
    
  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
      const handleHashChange = () => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            setActiveLink(hash);
          }
        }
  };
  
  window.addEventListener('hashchange', handleHashChange);
  
  return () => window.removeEventListener('hashchange', handleHashChange);
    }, []);
  
  const handleClick = (event, hash) => {
      event.preventDefault();
  
      const element = document.querySelector(hash);
      if (element) {
        const scrollOptions = {
          behavior: 'smooth',
          block: 'start', // Ensures smooth scrolling even with fixed headers
        };
        element.scrollIntoView(scrollOptions);
        setActiveLink(hash);
        window.history.pushState({}, '', hash); // Update URL without full page reload
      }
    };

    return (
        
      <>
        
        {
          links.map((link) => (
            <li className='nav-item' key={link.href}>
              <a href={link.href}
              className={activeLink === link.href ? 'nav-link active' : 'nav-link'}
              onClick={(e) => handleClick(e, link.href)}
              >{link.text}</a>
            </li>
          ))
        }
      </>

        
    )
}
