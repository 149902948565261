import React from 'react'
import './switcher-language.css'
import { useTranslation } from 'react-i18next'
import en from '../../../assets/imgs/en.webp'
import id from '../../../assets/imgs/id.webp'

export default function SwitcherLanguage() {

    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }

    return (
        <div>            
            {/* <button onClick={() => changeLanguage('en')}>English</button>
            <button onClick={() => changeLanguage('id')}>Indonesia</button> */}
             <div className="language-switcher">
                <button onClick={() => changeLanguage('en')}>
                    <img src={en} alt="English" />
                </button>
                <button onClick={() => changeLanguage('id')}>
                    <img src={id} alt="Indonesian" />
                </button>
            </div>
        </div>
    )
}
