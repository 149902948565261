import React, { useRef } from 'react'
import '../../assets/css/creative-studio.css'
import About from './About'
import Client from './Client'
import Contact from './Contact'
import Footer from './Footer'
import Navbar from './Navbar'
import Service from './Service'
import Teams from './Teams'

export default function IndexAnsgraph() {
  const scrollableElementRef = useRef(null);
  return (
  <>
    
    <body data-spy="scroll" data-target=".navbar" data-offset="40" id="home">
      <Navbar/>      
      <About/>
      <Service/>
      <Client/>
      <Teams/>
      <Contact/>
      <Footer/>
    </body>
    

    {/* <div style={{ 
      display: 'block', width: 600, paddingLeft: 30,  
      height: 1300, scrollBehavior: 'auto'
    }}> 
      <h4>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta rem obcaecati tempora aspernatur, laudantium cum velit totam, optio modi error ducimus. Quis aliquid cumque dolor quae amet totam ad enim?
      </h4> 
      <Affix> 
        <Button appearance="primary"> 
          Button
        </Button> 
      </Affix> 
      <h4>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta rem obcaecati tempora aspernatur, laudantium cum velit totam, optio modi error ducimus. Quis aliquid cumque dolor quae amet totam ad enim?
      </h4>
    </div>  */}

  </>
  )
}
