import React from 'react'
import imgTestimoni1 from '../../assets/imgs/avatar-5.jpg'
import imgTestimoni2 from '../../assets/imgs/avatar-6.jpg'
import logoYamaha from '../../assets/imgs/testimonial/yamaha.png'
import bankMega from '../../assets/imgs/testimonial/bank-mega.png'
import bca from '../../assets/imgs/testimonial/bca.jpg'
import ifg from '../../assets/imgs/testimonial/ifg.png'
import logoCallOfDragons from '../../assets/imgs/testimonial/callofdragons.jpg'
import logoBri from '../../assets/imgs/testimonial/logo-bri.png'
import newMakukuLogo from '../../assets/imgs/testimonial/new-makuku-logo.png'
import nusameta from '../../assets/imgs/testimonial/nusameta.png'
import rhb from '../../assets/imgs/testimonial/rhb.png'
import fauzan from '../../assets/imgs/fauzan.png'
import gulfy from '../../assets/imgs/gulfy.png'
import * as Unicons from '@iconscout/react-unicons';

export default function Client() {
  return (
    <div>
        <section id="testimonial">
            <div className="container">
                <h6 className="section-subtitle text-center">Testimonial</h6>
                <h6 className="section-title text-center mb-6">What Our Clients Says</h6>
                <div className="row mb-4">
                    <div className="col-md-6">
                        <div className="testimonial-wrapper">
                            <div className="img-holder">
                                <img src={fauzan} alt="#"/>
                            </div>
                            <div className="body">
                                <p className="subtitle">Studio animasi, audio, videography keren. Ngelayanin kebutuhan brand & iklan produk dgn style up to date👍</p>
                                <h6 className="title">Fauzan Sherenian</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="testimonial-wrapper">
                            <div className="img-holder">
                                <img src={gulfy} alt="#"/>
                            </div>
                            <div className="body">
                                <p className="subtitle">Service nya lengkap, tools nya ok! One stop solution 👍🏼</p>
                                <h6 className="title">Gulfy Megakresna</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-2">
                        <div className="img-wrapper">
                            <img src={logoYamaha} alt="#" />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="img-wrapper">
                            <img src={bankMega} alt="#" />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="img-wrapper">
                            <img src={bca} alt="#" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="img-wrapper">
                            <img src={ifg} alt="#" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="img-wrapper">
                            <img src={logoCallOfDragons} alt="#" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="img-wrapper">
                            <img src={logoBri} alt="#" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="img-wrapper">
                            <img src={newMakukuLogo} alt="#" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="img-wrapper">
                            <img src={nusameta} alt="#" />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="img-wrapper">
                            <img src={rhb} alt="#" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section className="has-bg-img py-lg">
            <div className="container text-center">
                <h6 className="section-title mt-4">See Our Intro Video</h6>
                <iframe width="80%" height="475" src="https://www.youtube.com/embed/as0dtDWbEZw" title="Reels Ansgraph Media" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </section>
    </div>
  )
}
