import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";

i18next.use(I18NextHttpBackend).use(i18nextBrowserLanguagedetector).use(initReactI18next)
.init({
    fallbackLng : 'en',
    debug: true,
    interpolation: {
        escapeValue: false,
    },
    backend: '/locales/{{lng}}/{{ns}}.json'
})

export default i18next