import React from 'react'
import tridAnimation from '../../assets/imgs/3d_animation.png'
import twodAnimation from '../../assets/imgs/2d_animation.png'
import audio from '../../assets/imgs/audio.png'
import motionGrafik from '../../assets/imgs/motion_grafik.png'
import videoKomersil from '../../assets/imgs/video_komersial.png'
import rateTridiAnimation from '../../assets/imgs/rate_3d_animation.png'
import rateTwodiAnimation from '../../assets/imgs/rate_2d_animation.png'
import rateAudio from '../../assets/imgs/rate_audio.png'
import rateMotionGrafik from '../../assets/imgs/rate_motion_grafik.png'
import rateVideoKomersil from '../../assets/imgs/rate_video_komersial.png'
import rateVideoTvc from '../../assets/imgs/rate_video_tvc.png'

export default function Service() {
  return (
    <div>
        <section id="service">
            <div className="container text-center">
                <h6 className="section-subtitle">Our Awesome Works</h6>
                <h6 className="section-title mb-5">Our Services</h6>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="img-wrapper">
                            <img src={tridAnimation} alt="#"/>
                            <div className="overlay">
                                <div className="overlay-infos">
                                    <h5>3D Animation</h5>
                                    <a href="javascript:void(0)"><i className="ti-zoom-in"></i></a>
                                    <a href="javascript:void(0)"><i className="ti-link"></i></a>
                                    {/* <img src={rateTridiAnimation} alt="#"/> */}
                                </div>  
                            </div>
                        </div>
                        <div className="img-wrapper">
                            <img src={twodAnimation} alt="#"/>
                            <div className="overlay">
                                <div className="overlay-infos">
                                    <h5>2D Animation</h5>
                                    <a href="javascript:void(0)"><i className="ti-zoom-in"></i></a>
                                    <a href="javascript:void(0)"><i className="ti-link"></i></a>
                                    {/* <img src={rateTwodiAnimation} alt="#"/> */}
                                </div>                              
                            </div>
                        </div>                  
                    </div>
                    <div className="col-sm-4">
                        <div className="img-wrapper">
                            <img src={audio} alt="#"/>
                            <div className="overlay">
                                <div className="overlay-infos">
                                    <h5>Audio</h5>
                                    <a href="javascript:void(0)"><i className="ti-zoom-in"></i></a>
                                    <a href="javascript:void(0)"><i className="ti-link"></i></a>
                                    {/* <img src={rateAudio} alt="#"/> */}
                                </div>  
                            </div>
                        </div>
                        <div className="img-wrapper">
                            <img src={motionGrafik} alt="#"/>
                            <div className="overlay">
                                <div className="overlay-infos">
                                    <h5>Motion Grafik</h5>
                                    <a href="javascript:void(0)"><i className="ti-zoom-in"></i></a>
                                    <a href="javascript:void(0)"><i className="ti-link"></i></a>
                                    {/* <img src={rateMotionGrafik} alt="#"/> */}
                                </div>                              
                            </div>
                        </div>                  
                    </div>
                    <div className="col-sm-4">
                        <div className="img-wrapper">
                            <img src={videoKomersil} alt="#"/>
                            <div className="overlay">
                                <div className="overlay-infos">
                                    <h5>Video Komersil</h5>
                                    <a href="javascript:void(0)"><i className="ti-zoom-in"></i></a>
                                    <a href="javascript:void(0)"><i className="ti-link"></i></a>
                                    {/* <img src={rateVideoKomersil} alt="#"/> */}
                                </div>  
                            </div>
                        </div>
                        <div className="img-wrapper">
                            <img src={videoKomersil} alt="#"/>
                            <div className="overlay">
                                <div className="overlay-infos">
                                    <h5>Video TVC</h5>
                                    <a href="javascript:void(0)"><i className="ti-zoom-in"></i></a>
                                    <a href="javascript:void(0)"><i className="ti-link"></i></a>
                                    {/* <img src={rateVideoTvc} alt="#"/> */}
                                </div>                              
                            </div>
                        </div>                  
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
