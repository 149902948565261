import React from 'react'
import logoAnsgraph from '../../assets/imgs/logo_ansgraph.png'
import * as Unicons from '@iconscout/react-unicons';
import SwitcherLanguage from './component/SwitcherLanguage';
import { useState, useEffect } from 'react';
import NavbarClicked from './component/NavbarClicked';
import { useTranslation } from 'react-i18next';
import { Affix } from 'rsuite';

export default function Navbar() {   

    const links = [
        { text: 'Home', href: '#home' },
        { text: 'About', href: '#about' },
        { text: 'Service', href: '#service' },
        { text: 'Testimonial', href: '#testimonial' },
        { text: 'Team', href: '#team' },
        { text: 'Contact', href: '#contact' },
    ];
    const { t } = useTranslation()

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        setIsScrolled(window.scrollY > 0);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => window.removeEventListener('scroll', handleScroll); // Cleanup
    }, []);

    const [isOpen, setIsOpen] = useState(false);

    const toggleNav = () => {
      setIsOpen(!isOpen);
    };

    const navClass = `navbar ${isOpen ? 'active' : ''}`;
    
    return (

        <>
            {/* <Affix> */}
            <nav className={`navbar custom-navbar navbar-expand-lg navbar-dark ${isScrolled ? 'scrolled' : ''}`} data-spy="affix" data-offset-top="20">
                <div className="container">
                    
                    <a className="navbar-brand" href="#">
                        <img src={logoAnsgraph} alt="#"/>
                    </a>
                    
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span>                            
                        </span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            
                            <NavbarClicked links={links}/>

                            <li className='nav-item'>
                                <SwitcherLanguage/>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </nav>
            {/* </Affix> */}

            <header className="header">
                <div className="overlay">
                    <h6 className="subtitle">{t('ansgraph')}</h6>
                    <h1 className="title">Ansgraph</h1>
                    <div className="buttons text-center">
                        {/* <a href="#service" className="btn btn-primary rounded w-lg btn-lg my-1">Our Service</a>
                        <a href="#contact" className="btn btn-outline-light rounded w-lg btn-lg my-1">Contact Us</a> */}
                    </div>              
                </div>      
            </header>

            <div className="box text-center" style={{borderRadius:'15px'}}>
                <div className="box-item">
                    <h6 className="box-title"><Unicons.UilEye color={'#249ecc'}/> {t('about.kelebihanTitle')}</h6>
                    <span>
                        {t('about.kelebihanKonten')}
                    </span>
                </div>
                <div className="box-item">
                    <h6 className="box-title"><Unicons.UilChartLine color={'#249ecc'}/>{t('about.transformasiTitle')}</h6>
                    <span>
                        {t('about.transformasiKonten')}
                    </span>
                </div>
                <div className="box-item">
                    <h6 className="box-title"><Unicons.UilCoins color={'#249ecc'}/> {t('about.manfaatTitle')}</h6>
                    <span>{t('about.manfaatKonten')}</span>
                </div>
            </div>            

        </>
    )
}
