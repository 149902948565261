import React from 'react'
import * as Unicons from '@iconscout/react-unicons'
import yusuf from '../../assets/imgs/teams/yusuf.png'
import rika from '../../assets/imgs/teams/rika.png'
import boik from '../../assets/imgs/teams/iqbal.png'
import morde from '../../assets/imgs/teams/morde.png'
import sultan from '../../assets/imgs/teams/sultan.png'
import vio from '../../assets/imgs/teams/vio.png'
import taufik from '../../assets/imgs/teams/taufik.png'

export default function Teams() {
  return (
    <div>
        <section id="team">
            <div className="container">
                <h6 className="section-subtitle text-center">Meet With</h6>
                <h6 className="section-title mb-5 text-center">Our Angels</h6>
                <div className="row">
                    <div className="col-sm-6 col-md-4">
                        <div className="card text-center mb-4">
                            <img className="card-img-top inset" src={yusuf}/>
                            <div className="card-body">
                                <h6 className="small text-primary font-weight-bold">CEO / Producer</h6>
                                <h5 className="card-title">Yusuf Muda Zamzam</h5>
                                
                                <div className="socials">
                                    <a href="javascript:void(0)"><Unicons.UilInstagram color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilTwitter color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilFacebook color={'#249ecc'}/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                        <div className="card text-center mb-4">
                            <img className="card-img-top inset" src={rika}/>
                            <div className="card-body">
                                <h6 className="small text-primary font-weight-bold">Finance</h6>
                                <h5 className="card-title">Rika Rahmawati</h5>
                                
                                <div className="socials">
                                    <a href="javascript:void(0)"><Unicons.UilInstagram color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilTwitter color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilFacebook color={'#249ecc'}/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                        <div className="card text-center mb-4">
                            <img className="card-img-top inset" src={morde}/>
                            <div className="card-body">
                                <h6 className="small text-primary font-weight-bold">Creative Head</h6>
                                <h5 className="card-title">Michael Mordekhai</h5>
                                
                                <div className="socials">
                                    <a href="javascript:void(0)"><Unicons.UilInstagram color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilTwitter color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilFacebook color={'#249ecc'}/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="card text-center mb-4">
                            <img className="card-img-top inset" src={boik}/>
                            <div className="card-body">
                                <h6 className="small text-primary font-weight-bold">Marketing Project</h6>
                                <h5 className="card-title">Iqbal Sabilar R</h5>
                                
                                <div className="socials">
                                    <a href="javascript:void(0)"><Unicons.UilInstagram color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilTwitter color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilFacebook color={'#249ecc'}/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="card text-center mb-4">
                            <img className="card-img-top inset" src={taufik}/>
                            <div className="card-body">
                                <h6 className="small text-primary font-weight-bold">SPV Post Product</h6>
                                <h5 className="card-title">Muhammad Taufik</h5>
                                
                                <div className="socials">
                                    <a href="javascript:void(0)"><Unicons.UilInstagram color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilTwitter color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilFacebook color={'#249ecc'}/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="card text-center mb-4">
                            <img className="card-img-top inset" src={vio}/>
                            <div className="card-body">
                                <h6 className="small text-primary font-weight-bold">Project Coordinator</h6>
                                <h5 className="card-title">Violetra</h5>
                                
                                <div className="socials">
                                    <a href="javascript:void(0)"><Unicons.UilInstagram color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilTwitter color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilFacebook color={'#249ecc'}/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <div className="card text-center mb-4">
                            <img className="card-img-top inset" src={sultan}/>
                            <div className="card-body">
                                <h6 className="small text-primary font-weight-bold">Editor / Generalist</h6>
                                <h5 className="card-title">Sultan Muhammad Thoriq</h5>
                                
                                <div className="socials">
                                    <a href="javascript:void(0)"><Unicons.UilInstagram color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilTwitter color={'#249ecc'}/></a>
                                    <a href="javascript:void(0)"><Unicons.UilFacebook color={'#249ecc'}/></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div>
  )
}
